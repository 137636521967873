import React, { Suspense, useEffect, useState, useRef } from "react";
import ImageLoader from "../chef/ImageLoader";
import "../login/login.css";
import AppContainer from "../util/AppContainer";
import { SuspenseImg } from "../util/SuspendImg";
import mainImage from "../../assets/loginTheme.png";
import MenuTopBar from "../menu/MenuTopBar";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  checkout,
  confirmOrder,
  sendOTP,
  setAddressForPreOrder,
  updateOrder,
  getOpenPaymentToken,
} from "../../store/loginActions";
import bottomBarSlice, { bottomBarActions } from "../../store/bottomBarSlice";
import ReactGA from "react-ga4";
// Import React Scrit Libraray to load Google object
import Script from "react-load-script";
import {
  API_TEST,
  APP_URL,
  DELIVERY_CHARGE_PER_KMS,
  FREE_DILIVERY_DISTANCE_RANGE,
  ORDER_DISTANCE_RANGE,
  PREP_TIME,
  S3_MENU_FOLDER,
  S3_MENU_THUMBNAIL_FOLDER,
  TEMP_EMAIL,
} from "../../store/constants";
import mixpanel from "mixpanel-browser";
import emailjs from "@emailjs/browser";
import { Checkbox } from "@material-ui/core";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import { loginActions } from "../../store/loginSlice";
import Dish from "../menu/Dish";
import ReactPixel from "react-facebook-pixel";
import { combineTheDate, extractPincode } from "../../utils/helper";
import moment from "moment";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import clevertap from "clevertap-web-sdk";

const CITY_NAME = "mumbai";
const GST_RATE = 5;

const Checkout = (props) => {
  const form = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState("");
  console.log(query);
  const [city, setCity] = useState("");
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [phonePeLoading, setPhonePeLoading] = useState(false);
  const userLocation = useSelector((state) => state.auth.location);
  const coords = useSelector((state) => state.auth.coords);
  const chefList = useSelector((state) => state.chef.chefList);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const isAreaServiceable = useSelector((state) => state.auth.isServiceable);
  const selectedAddress = useSelector((state) => state.auth.selectedAddress);
  const kitchensList = useSelector((state) => state.chef.kitchensList);
  const selectedDeliveryPortal = useSelector((state) => state.auth.selectedDeliveryPortal);
  const { cartData, instructionText, totalCost, chefWaitingTime, scheduleTime, isScheduleNow } = useSelector(({ cart }) => cart);
  const [loginState, setLoginState] = useState({
    email: "",
    area: "",
  });
  const deliveryOptions = useSelector((state) => state.auth.availableDeliveryOptions);
  const scheduleDateTime = combineTheDate(scheduleTime?.date, scheduleTime?.time);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorMessageForDelivery, setErrorMessageForDelivery] = useState("");

  // const delivery_distance = useSelector(
  //   ({ auth }) => auth.deliveryDetails?.delivery_distance
  // );
  // const delivery_duration = useSelector(
  //   ({ auth }) => auth.deliveryDetails?.delivery_duration?.value
  // );
  const autocompleteRef = useRef(null);
  const selectedOrder = useSelector((state) => state.auth.selectedOrder);
  const cartChefIds = selectedOrder?.bookedDishes ? selectedOrder?.bookedDishes?.map((a) => a?.dish?.chefId) : cartData.map((a) => a.chefId);
  const cartChefAddedKitchenId = chefList.find((a) => cartChefIds.includes(a._id))?.kitchenId;
  const deliveryDetails = useSelector(
    ({ auth }) => auth.deliveryDetails
  );
  const chefDeliveryDetails = deliveryDetails[cartChefAddedKitchenId] || {};
  const delivery_duration = chefDeliveryDetails?.delivery_duration?.value;
  const delivery_distance = chefDeliveryDetails?.delivery_distance;
  const kitchenLocation = chefDeliveryDetails?.kitchenLocation;
  const allChefsWaitingTime = chefWaitingTime?.map((chef) => {
    const currentChef = cartData.filter((a) => a.chefId === chef.chefId).map((a) => a.portion).reduce((partialSum, a) => partialSum + a, 0) * 5;
    return 0 < currentChef ? chef.chefWaitTime + currentChef : 0;
  })

  const preparationTime = Math.max(PREP_TIME, Math.max(...allChefsWaitingTime))
  let delivery_Time = delivery_duration
    ? Math.round(delivery_duration / 60) + preparationTime
    : 0;

  const openPaymentToken = useSelector((state) => state.auth.openPaymentToken);
  const [paymentMode, setPaymentMode] = useState("online");
  const [confirmDisabled, setConfirmDisabled] = useState(false);

  const handleChangePaymentMode = (mode) => {
    if (!loading) {
      setPaymentMode(mode)
    }
  }

  const handleScriptLoad = () => {
    const options = {
      fields: ["formatted_address", "geometry", "name", "address_component"],
      componentRestrictions: { country: "In" },
      strictBounds: false,
      //types: ["geocode"],
    };
    // Initialize Google Autocomplete
    /*global google*/ // To disable any eslint 'google not defined' errors
    autocompleteRef.current = new google.maps.places.Autocomplete(
      document.getElementById("searchAutoComplete"),
      options
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    autocompleteRef.current.setFields([
      "address_components",
      "formatted_address",
    ]);

    // Fire Event when a suggested name is selected
    autocompleteRef.current.addListener("place_changed", function () {
      const addressObject = autocompleteRef.current.getPlace();
      console.log(addressObject);
      console.log(autocompleteRef.current.getPlace());
      let components = addressObject.address_components,
        // console.log(addressObject.name)
        // let components = addressObject.,

        city = "n/a";
      if (components) {
        for (let c = 0; c < components.length; ++c) {
          console.log(components[c].types.join("|"));
          if (
            components[c].types.indexOf("locality") > -1 &&
            components[c].types.indexOf("political") > -1
          ) {
            city = components[c].long_name;
            break;
          }
        }
      }
      if (addressObject && addressObject.geometry) {
        const locationObject = {
          lat: addressObject.geometry.location.lat(),
          lng: addressObject.geometry.location.lng(),
          area: addressObject.formatted_address,
        };
        setLocation(locationObject);
      }
      setCity(city);
      setQuery(
        addressObject
          ? `${addressObject.name},${addressObject.formatted_address}`
          : ""
      );
      console.log(addressObject);
    });
  };

  useEffect(() => {
    // ReactGA.send({ hitType: "pageview", page: "/checkout" });
    ReactGA.event({
      category: "tab",
      action: "checkout",
    });
  }, []);

  useEffect(() => {
    if (openPaymentToken) {
      window.triggerLayer(openPaymentToken);
    }
  }, [openPaymentToken]);

  const getTotal = () => {
    let total = 0;
    selectedOrder.bookedDishes.forEach((dish) => {
      total = total + (dish.isAddedOnDiscount ? dish.dealPrice : dish.price);
    });
    return total;
  };

  const getDeliveryFee = () => {
    const pidgeDeliveryOptions = deliveryOptions?.PIDGE || [];
    const porterDeliveryOptions = deliveryOptions?.PORTER || [];
  
    if (deliveryOptions?.SHADOWFAX?.is_serviceable) {
      // SHADOWFAX delivery available
      dispatch(loginActions.setSelectedDeliveryPortal("SHADOWFAX"));
      return parseInt(deliveryOptions?.SHADOWFAX.total_amount, 10);
    }
  
    if (delivery_distance && isAreaServiceable && pidgeDeliveryOptions.length) {
      // PIDGE delivery available
      dispatch(loginActions.setSelectedDeliveryPortal("PIDGE"));
      const lowestPriceNetwork = pidgeDeliveryOptions.reduce((lowest, current) => {
        return current?.quote?.price < lowest?.quote?.price ? current : lowest;
      })?.quote?.price;
      return parseInt(lowestPriceNetwork, 10);
    }
  
    if (delivery_distance && isAreaServiceable && porterDeliveryOptions.length) {
      // PORTER delivery available
      dispatch(loginActions.setSelectedDeliveryPortal("PORTER"));
      const number = porterDeliveryOptions[0]?.fare?.minor_amount;
      return parseInt((number / 100).toFixed(2), 10);
    }
  
    dispatch(loginActions.setSelectedDeliveryPortal(""));
    return 0;
  };
  
  const getGSTAmount = (total) => {
    // Calculate 5% GST
    return (parseInt(total) * GST_RATE) / 100;
  };

  const getPackagingAmount = (schedule) => {
    let packagingFee = 0;
    if (schedule) {
      selectedOrder.bookedDishes.forEach((cart) => {
        if (cart.isAddedOnDiscount) {
          packagingFee = packagingFee + cart.dish.packagingCost;
        }
      });
    } else {
      cartData.forEach((cart) => {
        if (cart.isAddedOnDiscount) {
          packagingFee = packagingFee + cart.packagingCost;
        }
      });
    }
    return packagingFee;
  };

  const sendEmail = (e) => {
    const mobileNumber = "91" + user.phonenumber;
    dispatch(confirmOrder({ mobileNumber }));
    console.log("Order sucessfull sms sent");
    emailjs
      .sendForm(
        "service_3t471gh", // replace with your service id
        "template_1d10sj4", // replace with your template id
        form.current,
        "kWiFDOBeK7Oc_Y7Jg" // replace with your public key
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    console.log("Order sucessfull email sent");
  };

  const checkoutCart = (bookForLater) => {
    if (!moment().isBefore(moment(scheduleDateTime)) && moment(scheduleDateTime).isValid()) {
      toast.error("Please select a valid date and time")
      return
    }

    mixpanel.track("checked-out");
    // clevertap.event.push("checked-out");
    if (loginState.email) {
      setErrorMessageForDelivery("");
      setError(false);
      if (
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(loginState.email)
      ) {
        setError(true);
        setErrorText("Email id is not valid");
        return;
      }

      let newAddress = {
        area: "",
        houseNumber: "",
        landMark: "",
        location: {
          lat: coords.lat,
          lng: coords.lng,
          locationName: userLocation,
        },
        tag: "default",
      };
      const cartDataClone = cartData.map((data) => {
        const customizeObj = data?.customizeObj || [];
        return { ...data, customizeObj: customizeObj };
      });

      dispatch(setAddressForPreOrder(user.userid, newAddress));
      dispatch(loginActions.setSelectedAddress(newAddress));
      const orderType = isAreaServiceable ? "order-now" : "pre-launch";
      let deliveryFee = getDeliveryFee();
      let packagingFee = getPackagingAmount(false);
      let gstFee = getGSTAmount(totalCost);
      let billingAmount = Math.round(
        totalCost + gstFee + deliveryFee + packagingFee
      );
      let scheduleDateTimeValue = scheduleDateTime;
      scheduleDateTimeValue.setHours(scheduleDateTimeValue.getHours() + 5);
      scheduleDateTimeValue.setMinutes(scheduleDateTimeValue.getMinutes() + 30);
      // const chefId = cartDataClone[0].chefId;
      // const kitchenId = chefList.find((a) => a._id === chefId)?.kitchenId;
      // const chefInfo = chefList.find((a) => a._id === chefId);
      // const kitchenInfo = kitchensList.find((a) => a._id === kitchenId);
      // const pickup_details = {
      //   "name": kitchenInfo.name,
      //   "contact_number": kitchenInfo.phoneNumber,
      //   "address": kitchenInfo.address,
      //   "landmark": kitchenInfo.landmark,
      // }
      // const drop_details = {
      //   "name": user.username,
      //   "contact_number": user.phonenumber,
      //   "address": selectedAddress.location.locationName,
      //   "latitude": selectedAddress.location.lat,
      //   "longitude": selectedAddress.location.lng
      // }
      dispatch(
        checkout(
          cartDataClone,
          user,
          loginState.email,
          newAddress,
          orderType,
          deliveryFee,
          delivery_distance,
          delivery_Time,
          totalCost,
          packagingFee,
          gstFee,
          scheduleDateTimeValue,
          billingAmount,
          null,
          null,
          {},
          {},
          {},
          {},
          "",
          false
        )
      );
      sendEmail();
      ReactPixel.track("Purchase", { value: billingAmount, currency: "INR" });
    } else {
      if (!loginState.email) {
        setError(true);
        setErrorText("Email id is required");
        return;
      } else if (!loginState.area) {
        setError(true);
        setErrorText("Address is required");
        return;
      }
    }
  };

  const sendEmailOnConfirm = (billingAmount) => {
    let dishBookedName = "";
    cartData.forEach((data) => {
      dishBookedName += data.name + ", ";
    });

    const { username, phonenumber } = user;

    const mobileNumber = "91" + phonenumber;
    let templateParams = {
      user_name: username,
      phone_number: mobileNumber,
      dish_name: dishBookedName,
      area: selectedAddress.area,
      houseNumber: selectedAddress.houseNumber,
      landmark: selectedAddress.landMark,
      locationName: selectedAddress.location.locationName,
      billingAmount: billingAmount,
      deliveryTime: delivery_Time,
      lat: selectedAddress.location.lat,
      lng: selectedAddress.location.lng,
      tag: selectedAddress.tag,
      to_email: TEMP_EMAIL,
    };
    dispatch(confirmOrder({ mobileNumber }));
    emailjs
      .send(
        "service_3t471gh", // replace with your service id
        "template_3x8ijk9", // replace with your template id
        templateParams,
        "kWiFDOBeK7Oc_Y7Jg" // replace with your public key
      )
      .then((res) => console.log(res.text))
      .catch((error) => console.log(error));
  };

  const processOrder = async (paymentId) => {
    if (!moment().isBefore(moment(scheduleDateTime)) && moment(scheduleDateTime).isValid()) {
      toast.error("Please select a valid date and time")
      return
    }
    try {
      setLoading(true);
      setPhonePeLoading(true)
      const orderType = isAreaServiceable ? "order-now" : "pre-launch";
      let deliveryFee = getDeliveryFee();
      let packagingFee = getPackagingAmount(false);
      let gstFee = getGSTAmount(totalCost);
      let billingAmount = Math.round(
        totalCost + gstFee + deliveryFee + packagingFee
      );
      if (selectedOrder?.bookedDishes) {
        let scheduleDateTimeValue = moment(scheduleTime).isValid() ? new Date(scheduleTime) : "";
          if (scheduleDateTimeValue) {
            scheduleDateTimeValue.setHours(scheduleDateTimeValue.getHours() + 5);
            scheduleDateTimeValue.setMinutes(scheduleDateTimeValue.getMinutes() + 30);
          }
        const tempSelectedOrder = {...selectedOrder};
        tempSelectedOrder.scheduleTime = scheduleTime || "NOW";
        tempSelectedOrder.deliveryTime = delivery_Time;
        await dispatch(updateOrder(tempSelectedOrder, getDeliveryFee(true)));
      } else {
        if (paymentMode === "online") {
          if (!paymentId) {
            toast.error("Error while verifying the payment status")
          }
          const storedCheckoutData = localStorage.getItem("checkoutData");
          const {
            cartDataClone,
            user,
            email,
            address,
            orderType,
            deliveryFee,
            deliveryDistance,
            deliveryTime,
            totalCost,
            packagingFee,
            gstFee,
            instructionText,
            chefAddress,
            scheduleTime,
            billingAmount,
            paymentMode,
            pickup_details,
            drop_details,
            sender_detail,
            receiver_detail
          } = JSON.parse(storedCheckoutData);
          let scheduleDateTimeValue = moment(scheduleTime).isValid() ? new Date(scheduleTime) : "";
          if (scheduleDateTimeValue) {
            scheduleDateTimeValue.setHours(scheduleDateTimeValue.getHours() + 5);
            scheduleDateTimeValue.setMinutes(scheduleDateTimeValue.getMinutes() + 30);
          }
          await dispatch(
            checkout(
              cartDataClone,
              user,
              email,
              address,
              orderType,
              deliveryFee,
              deliveryDistance,
              deliveryTime,
              totalCost,
              packagingFee,
              gstFee,
              instructionText,
              chefAddress,
              scheduleDateTimeValue,
              billingAmount,
              paymentMode,
              paymentId,
              pickup_details,
              drop_details,
              sender_detail,
              receiver_detail,
              selectedDeliveryPortal
            )
          );
        } else {
          const cartDataClone = cartData.map((data) => {
            const customizeObj = data?.customizeObj || [];
            return { ...data, customizeObj: customizeObj };
          });
          let scheduleDateTimeValue = scheduleDateTime;
          scheduleDateTimeValue.setHours(scheduleDateTimeValue.getHours() + 5);
          scheduleDateTimeValue.setMinutes(scheduleDateTimeValue.getMinutes() + 30);
          const chefId = cartDataClone[0].chefId;
          const kitchenId = chefList.find((a) => a._id === chefId)?.kitchenId;
          const kitchenInfo = kitchensList.find((a) => a._id === kitchenId);
          const sender_detail = {
            address: {
              address_line_1: kitchenInfo?.address || "",
              address_line_2: "",
              label: "Kitchen",
              landmark: kitchenInfo.landmark,
              city: kitchenInfo.city || "Mumbai",
              state: kitchenInfo.state || "Maharashtra",
              country: "India",
              pincode: kitchenInfo.pincode || "400001",
              latitude: kitchenInfo.latitude || "19.116606", // sender latitude
              longitude: kitchenInfo.longitude || "72.8854982", // sender longitude
              instructions_to_reach: "Use Sender GPS"
            },
            name: kitchenInfo.name,
            mobile: kitchenInfo.phoneNumber,
            email: "cuirato@yopmail.com"
          };
          const receiver_detail = {
            address: {
              address_line_1: selectedAddress.location.locationName,
              label: selectedAddress.tag,
              landmark: selectedAddress.landMark || "Andheri",
              city: "Mumbai",
              state: "Maharashtra",
              country: "India",
              pincode: extractPincode(selectedAddress.location.locationName),
              latitude: selectedAddress.location.lat, // receiver latitude
              longitude: selectedAddress.location.lng, // receiver longitude
              instructions_to_reach: "Receiver GPS"
            },
            name: user.username,
            mobile: user.phonenumber,
            email: "receiver@email.com"
          }

          const pickup_details = {
            "name": kitchenInfo.name,
            "contact_number": kitchenInfo.phoneNumber,
            "address": kitchenInfo.address,
            "landmark": kitchenInfo.landmark,
          }
          const drop_details = {
            "name": user.username,
            "contact_number": user.phonenumber,
            "address": selectedAddress.location.locationName,
            "latitude": selectedAddress.location.lat,
            "longitude": selectedAddress.location.lng
          }
          await dispatch(
            checkout(
              cartDataClone,
              user,
              "",
              selectedAddress,
              orderType,
              deliveryFee,
              delivery_distance,
              delivery_Time,
              totalCost,
              packagingFee,
              gstFee,
              instructionText || "",
              kitchenLocation,
              scheduleDateTimeValue,
              billingAmount,
              paymentMode,
              paymentId,
              pickup_details,
              drop_details,
              sender_detail,
              receiver_detail,
              selectedDeliveryPortal
            )
          );
        }

      }
      sendEmailOnConfirm(billingAmount);
      mixpanel.track("checked-out");
      // clevertap.event.push("checked-out");
      ReactPixel.track("Purchase", { value: billingAmount, currency: "INR" });
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setLoading(false);
      setPhonePeLoading(false)
      deletePaymentParams()
    }
  };

  const deletePaymentParams = () => {
    dispatch(loginActions.setOpenPaymentToken(null));
    setConfirmDisabled(false);
    setLoading(false);
    if (searchParams.has("payment")) {
      const token = searchParams.get("payment");
      if (token) {
        searchParams.delete("payment");
        setSearchParams(searchParams);
      }
    }
    if (searchParams.has("merchantUserId")) {
      const merchantUserId = searchParams.get("merchantUserId");
      if (merchantUserId) {
        searchParams.delete("merchantUserId");
        setSearchParams(searchParams);
      }
    }
  }

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const merchantTransactionId = urlParams.get('payment')
    const merchantUserId = urlParams.get('merchantUserId')
    if (!!merchantTransactionId && !!merchantUserId) {
      setPhonePeLoading(true);
      const xVerifyUrl = `/pg/v1/status/M1WKA4ZCTQM4/${merchantTransactionId}` + "642259ee-bbef-40d0-82a2-43909afecaf1";
      const xVerifyUrlData = new TextEncoder().encode(xVerifyUrl);

      window.crypto.subtle.digest('SHA-256', xVerifyUrlData)
        .then(hashBuffer => {
          const hashArray = Array.from(new Uint8Array(hashBuffer));
          const sha256Hash = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

          const axiosOptions = {
            method: 'get',
            url: `https://phonepe-topaz.vercel.app/demo/api/phonepe-payment-status?xVerify=${sha256Hash}&merchantTransactionId=${merchantTransactionId}&merchantUserId=${merchantUserId}`,
            headers: {
              accept: 'application/json',
              'Content-Type': 'application/json',
            }
          };

          axios(axiosOptions)
            .then(response => {
              const { code, message } = response?.data?.message || {};
              if (code === "PAYMENT_SUCCESS") {
                if (response?.data?.message?.data?.transactionId) {
                  processOrder(response?.data?.message?.data?.transactionId);
                }
              } else {
                toast.error(message)
                deletePaymentParams()
                setPhonePeLoading(false)
              }
            })
            .catch(error => {
              toast.error("Error while verifying the payment status")
              console.error(error);
              setPhonePeLoading(false);
            });
        })
        .catch(error => {
          console.error(error);
          setPhonePeLoading(false);
        })

    } else {
      deletePaymentParams()
    }
  }, []);

  window.onPaymentResponse = (response) => {
    if (response.status == "cancelled" || response.status == "failed") {
      dispatch(loginActions.setOpenPaymentToken(null));
      setConfirmDisabled(false);
    } else {
      processOrder(response.payment_id);
      dispatch(loginActions.setOpenPaymentToken(null));
      setConfirmDisabled(false);
    }
  };


  const handlePhonePeClick = (billingAmount, phonenumber) => {
    const appUrlVal = APP_URL;
    const appUrl  = appUrlVal.replace('https://', 'https://www.').replace(/\/$/, '');
    const merchantTransactionId = 'MT' + Date.now();
    const merchantUserId = "MUID" + Date.now()
    if (billingAmount === 0) return
    const payload = {
      "merchantId": "M1WKA4ZCTQM4",
      "merchantTransactionId": merchantTransactionId,
      "merchantUserId": merchantUserId,
      "amount": billingAmount + "00",
      "redirectUrl": `${appUrl}?payment=${merchantTransactionId}&merchantUserId=${merchantUserId}`,
      "redirectMode": "REDIRECT",
      "callbackUrl": "",
      "mobileNumber": phonenumber,
      "paymentInstrument": {
        "type": "PAY_PAGE"
      }
    };
    const payloadString = JSON.stringify(payload);
    const base64Payload = btoa(payloadString);
    const concatenatedString = base64Payload + '/pg/v1/pay' + "642259ee-bbef-40d0-82a2-43909afecaf1";
    const data = new TextEncoder().encode(concatenatedString);
    window.crypto.subtle.digest('SHA-256', data)
      .then(hashBuffer => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const sha256Hash = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
        const axiosOptions = {
          method: 'GET',
          url: `${API_TEST}phonepe-payment?xVerify=${sha256Hash}&request=${base64Payload}`,
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          }
        };
        setPhonePeLoading(true)
        axios(axiosOptions)
          .then(response => {
            const { data, message, statusCode } = response?.data
            if (statusCode === 200) {
              if (data?.instrumentResponse?.redirectInfo?.url) {
                const newUrl = data?.instrumentResponse?.redirectInfo?.url;
                setPhonePeLoading(false)
                // window.location.replace(newUrl, false);
                window.location.href = newUrl;
              }
            } else {
              setPhonePeLoading(false)
              toast.error(message)
            }
          })
          .catch(error => {
            setPhonePeLoading(false)
            console.error(error);
          });
      })
      .catch(error => {
        setPhonePeLoading(false)
        console.error(error);
        setLoading(false);
      })
  };

  const onConfirmHandler = () => {
    setConfirmDisabled(true);
    let deliveryFee = getDeliveryFee();
    let packagingFee = getPackagingAmount(false);
    let gstFee = getGSTAmount(totalCost);
    let billingAmount = Math.round(
      totalCost + gstFee + deliveryFee + packagingFee
    );
    switch (paymentMode) {
      case "online":
        // if(user.referral.toLowerCase() === "test"){
        const orderType = isAreaServiceable ? "order-now" : "pre-launch";
        const alreadyPreOrdered = !!selectedOrder && Object.keys(selectedOrder).length !== 0; //check whether order was already in pre-order so use that data
        const totalCostBill = alreadyPreOrdered ? selectedOrder.totalCost : totalCost
        let deliveryFee = getDeliveryFee();
        let packagingFee = alreadyPreOrdered ? selectedOrder.packagingFee : getPackagingAmount(false);
        let gstFee = getGSTAmount(totalCostBill);
        let billingAmount = Math.round(
          totalCostBill + gstFee + deliveryFee + packagingFee
        );
        const dishesList = alreadyPreOrdered ? selectedOrder?.bookedDishes : cartData; 
        const cartDataClone = dishesList?.map((data) => {
          const customizeObj = data?.customizeObj || [];
          return { ...data, customizeObj: customizeObj };
        });
        const chefId = dishesList[0]?.chefId || dishesList[0]?.dish?.chefId;
        const kitchenId = chefList.find((a) => a._id === chefId)?.kitchenId;
        const kitchenInfo = kitchensList.find((a) => a._id === kitchenId);
        const pickup_details = {
          "name": kitchenInfo.name,
          "contact_number": kitchenInfo.phoneNumber,
          "address": kitchenInfo.address,
          "landmark": kitchenInfo.landmark,
        }
        const drop_details = {
          "name": user.username,
          "contact_number": user.phonenumber,
          "address": selectedAddress.location.locationName,
          "latitude": selectedAddress.location.lat,
          "longitude": selectedAddress.location.lng
        }

        const checkoutData = {
          cartDataClone,
          user,
          email: "",
          address: selectedAddress,
          orderType: orderType,
          deliveryFee: deliveryFee,
          deliveryDistance: delivery_distance,
          deliveryTime: delivery_Time,
          totalCost: totalCost,
          packagingFee: packagingFee,
          gstFee: gstFee,
          instructionText: instructionText || "",
          chefAddress: kitchenLocation,
          scheduleTime: scheduleDateTime,
          billingAmount: billingAmount,
          paymentMode: paymentMode,
          paymentId: "",
          pickup_details: pickup_details,
          drop_details: drop_details,
        };
        if (!moment().isBefore(moment(scheduleDateTime)) && moment(scheduleDateTime).isValid()) {
          toast.error("Please select a valid date and time for schedule time")
          return
        }
        localStorage.setItem("checkoutData", JSON.stringify(checkoutData));
        handlePhonePeClick(billingAmount, user.phonenumber)
        // }else{
        //   const paymentId = uuidv4();
        // dispatch(getOpenPaymentToken(paymentId, billingAmount, user.phonenumber));
        // }

        break;
      case "cash":
        processOrder();
        break;
      case "upi":
        processOrder();
        break;
    }
  };

  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
  };

  // This function calculate the distance between two end point
  const distance = (
    sourceLat,
    sourceLon,
    destinationLat,
    destinationLon,
    unit
  ) => {
    if (sourceLat == destinationLat && sourceLon == destinationLon) {
      return 0;
    } else {
      var radlat1 = (Math.PI * sourceLat) / 180;
      var radlat2 = (Math.PI * destinationLat) / 180;
      var theta = sourceLon - destinationLon;
      var radtheta = (Math.PI * theta) / 180;
      var dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == "K") {
        dist = dist * 1.609344;
      }
      if (unit == "N") {
        dist = dist * 0.8684;
      }
      return dist;
    }
  };

  const onBackClick = () => {
    selectedOrder?.bookedDishes ? switchTab("dishBook") : switchTab("cart");
  };

  const [isChecked, setIsChecked] = useState(false);

  const deliveryLabel = !isScheduleNow && moment(scheduleDateTime).isValid() ? `Delivery at ${moment(scheduleDateTime).format("MMM D, h:mm a")}` : "Deliver now"

  return (
    <AppContainer id={props.id}>
      <Script
        url="https://maps.googleapis.com/maps/api/js?key=AIzaSyDSrNgyDHSkqDzUMNIJEbsLt3u_q1RbjYw&libraries=places"
        onLoad={handleScriptLoad}
      />
      <div className="cart-top-bar">
        <MenuTopBar
          name="Checkout"
          cuisine={isAreaServiceable ? deliveryLabel : "Pre-order"}
          notMenu={true}
          onBackClick={onBackClick}
        />
      </div>
      <form
        className="login-container checkout-container"
        ref={form}
        onSubmit={(e) => e.preventDefault()}
      >
        <Suspense fallback={<ImageLoader className="loader_wrapper" />}>
          <div className="mt">
            <SuspenseImg src={mainImage}></SuspenseImg>{" "}
          </div>
        </Suspense>

        {isAreaServiceable && selectedOrder ? (
          <>
            <div className="food-accordian">
              <p className="cart-header">Order Details</p>
              <div className="dish-wrapper-list">
                {selectedOrder?.bookedDishes.map((dish, i) => {
                  return (
                    <div className="price-box">
                      <div className="items-price items dishes">
                        <div className="price-title">{dish.dish.name} x {dish.portion}</div>
                        <div className="price-value">
                          {parseInt(
                            dish.isAddedOnDiscount ? dish.dealPrice : dish.price
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
                {selectedOrder?.instructionText && (
                  <div className="price-box">
                    <div className="price-title">Add cooking instruction</div>
                    <textarea
                      value={selectedOrder?.instructionText}
                      disabled
                      className="instruction-textarea"
                    />
                  </div>
                )}
                <div className="billing-section mx-2">
                  <div className="billing-title">Billing Details</div>
                  <div className="price-box">
                    <div className="items-price items__first">
                      <div className="price-title">Items Price</div>
                      <div className="price-value">
                        {" "}
                        &#8377; {parseInt(selectedOrder.totalCost)}{" "}
                      </div>
                    </div>
                    <div className="items-price items">
                      <div className="price-title">Delivery Fees</div>
                      <div className="price-value">
                        {" "}
                        &#8377; {getDeliveryFee(true)}{" "}
                      </div>
                    </div>
                    <div className="items-price items">
                      <div className="price-title">Packaging</div>
                      <div className="price-value">
                        {" "}
                        &#8377; {selectedOrder.packagingFee}{" "}
                      </div>
                    </div>
                    <div className="items-price items">
                      <div className="price-title">GST</div>
                      <div className="price-value">
                        {" "}
                        &#8377; {getGSTAmount(selectedOrder.totalCost)}{" "}
                      </div>
                    </div>
                    <div className="items-price items__total">
                      <div className="price-title">Total</div>
                      <div className="price-value">
                        {" "}
                        &#8377;{" "}
                        {Math.round(
                          selectedOrder.totalCost +
                          selectedOrder.gstFee +
                          getDeliveryFee(true) +
                          selectedOrder.packagingFee
                        )}{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="billing-title">Billing Details</div>
            <div
              style={{ marginTop: 20 }}
              className="payon_delivery-container"
              onClick={() => handleChangePaymentMode("upi")}
            >
              <h6> UPI on delivery</h6>
              <input
                type="checkbox"
                checked={paymentMode === "upi"}
                disabled={loading}
                value="upi"
              />
            </div>
            <div
              className="payon_delivery-container"
              onClick={() => handleChangePaymentMode("cash")}
            >
              <h6> Cash on delivery</h6>
              <input
                type="checkbox"
                checked={paymentMode === "cash"}
                disabled={loading}
                value="cash"
              />
            </div>
            <div
              className="payon_delivery-container"
              onClick={() => handleChangePaymentMode("online")}
            >
              <h6> Pay online</h6>
              <input
                type="checkbox"
                checked={paymentMode === "online"}
                disabled={loading}
                value="online"
              />
            </div>
            <div
              style={{ marginBottom: 20 }}
              className="payon_delivery-container"
            >
              <p className="pay-info">Secure transaction via Phonepe</p>
            </div>
          </>
        ) : isAreaServiceable ? (
          <>
            <div
              style={{ marginTop: 0 }}
              className="payon_delivery-container"
              onClick={() => handleChangePaymentMode("upi")}
            >
              <h6> UPI on delivery</h6>
              <input
                type="checkbox"
                checked={paymentMode === "upi"}
                disabled={loading}
                value="upi"
              />
            </div>
            <div
              className="payon_delivery-container"
              onClick={() => handleChangePaymentMode("cash")}
            >
              <h6> Cash on delivery</h6>
              <input
                type="checkbox"
                checked={paymentMode === "cash"}
                disabled={loading}
                value="cash"
              />
            </div>
            <div
              className="payon_delivery-container"
              onClick={() => handleChangePaymentMode("online")}
            >
              <h6> Pay online</h6>
              <input
                type="checkbox"
                checked={paymentMode === "online"}
                disabled={loading}
                value="online"
              />
            </div>
            <div
              style={{ marginBottom: 20 }}
              className="payon_delivery-container"
            >
              <p className="pay-info">Secure transaction via Phonepe</p>
            </div>
          </>
        ) : (
          <>
            <div className="logo-container">
              <h5>Checkout</h5>
              <p>
                We will update you when your preferred chefs go live. You will
                be able schedule your delivery through the app.
              </p>
            </div>
            <div className={error ? "" : "hide"}>
              <p className="error-msg">{errorText}</p>
            </div>
            <label htmlFor="email">
              Email
              <input
                id="email"
                type="email"
                className="login-phone input-text"
                name="email"
                onChange={({ target }) => {
                  setLoginState({
                    ...loginState,
                    [target.name]: target.value,
                  });
                  setError("");
                  setErrorText("");
                }}
                placeholder="Enter your email"
                value={loginState.email}
              />
            </label>

            {/* <label htmlFor="searchAutoComplete">
              Address
              <input
                id="searchAutoComplete"
                type="text"
                className="login-phone"
                name="area"
                onChange={({ target }) => {
                  setQuery("");
                  setLoginState({
                    ...loginState,
                    [target.name]: target.value,
                  });
                  setErrorMessageForDelivery("");
                }}
                placeholder="Enter your address"
                value={query ? query : loginState.area}
              />
            </label> */}
          </>
        )}

        {errorMessageForDelivery && (
          <p className="checkout-error-msg"> {errorMessageForDelivery} </p>
        )}
        <div className="checkout-option active" id="cart_option">
          {isAreaServiceable ? (
            <button
              type="submit"
              className={`bottom-button ${(loading || phonePeLoading) && `button--loading`}`}
              disabled={confirmDisabled || loading || phonePeLoading}
              onClick={() => {
                onConfirmHandler();
              }}
            >
              CONFIRM
            </button>
          ) : (
            <button
              type="submit"
              disabled={loginState.email === "" ? true : false}
              onClick={() => checkoutCart(errorMessageForDelivery && true)}
              className={`bottom-button ${loginState.email === "" ? `disabled` : ""
                }`}
            >
              {errorMessageForDelivery ? "Book for later" : "Book"}
            </button>
          )}
        </div>
      </form>
      <ToastContainer limit={1} />
    </AppContainer>
  );
};

export default Checkout;
